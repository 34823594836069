import axios from "../axios/axios-kaqadmin";
import {useBearerTokenHeaders} from "../utils/functions";

export const driversService = {
    getDrivers,
    getDriver,
    searchDrivers,
    addDriver,
    updateDriver,
    assignProviderExternalIdentifier,
    fetchProviderExternalIdentifier,
    changeDriverPassword,
    getFDataExemptionStatus,
    setFDataExemptionStatus
}

function getDrivers(limit = 5, status = ''){
    let endPoint = '/drivers/limit/' + limit;
    if(status && status !== ''){
        endPoint = endPoint + '/' + status
    }
    return new Promise((resolve, reject) => {
        axios.get(endPoint , useBearerTokenHeaders())
            .then(response => {
                resolve(response.data)
            }).catch(err => resolve({status: false, message: err}));

    })
}

function getDriver(id){
    return new Promise((resolve, reject) => {
        axios.get('/drivers/driver/' + id, useBearerTokenHeaders())
            .then(response => {
                resolve(response.data)
            }).catch(err => resolve({status: false, message: err}));
    })
}

function searchDrivers(value){
    return new Promise((resolve, reject) => {
        axios.post('/drivers/search',{value}, useBearerTokenHeaders())
            .then(response => {
                resolve(response.data)
            }).catch(err => resolve({status: false, message: err}));
    })
}

function addDriver(payload){
    return new Promise((resolve, reject) => {
        axios.post('/drivers/add',payload, useBearerTokenHeaders())
            .then(response => {
                resolve(response.data)
            }).catch(err => resolve({status: false, message: err}));
    })
}

function updateDriver(payload, id){
    return new Promise((resolve, reject) => {
        axios.post('/drivers/update/' + id,payload, useBearerTokenHeaders())
            .then(response => {
                resolve(response.data)
            }).catch(err => resolve({status: false, message: err}));
    })
}

function changeDriverPassword(payload){
    return new Promise((resolve, reject) => {
        axios.post('/drivers/change-driver-password',payload, useBearerTokenHeaders())
            .then(response => {
                resolve(response.data)
            }).catch(err => resolve({status: false, message: err}));
    })
}

function assignProviderExternalIdentifier(payload){
    return new Promise((resolve, reject) => {
        axios.post('/drivers/assign-provider-ext-id-to-driver',payload, useBearerTokenHeaders())
            .then(response => {
                resolve(response.data)
            }).catch(err => resolve({status: false, message: err}));
    })
}

function fetchProviderExternalIdentifier(id){
    return new Promise((resolve, reject) => {
        axios.get('/drivers/fetch-provider-ext-id-of-driver/' + id, useBearerTokenHeaders())
            .then(response => {
                resolve(response.data)
            }).catch(err => resolve({status: false, message: err}));
    })
}

function getFDataExemptionStatus(payload){
    return new Promise((resolve, reject) => {
        axios.post('/drivers/get-f-data-exemption-status',payload, useBearerTokenHeaders())
            .then(response => {
                resolve(response.data)
            }).catch(err => resolve({status: false, message: err}));
    })
}

function setFDataExemptionStatus(payload){
    return new Promise((resolve, reject) => {
        axios.post('/drivers/set-f-data-exemption-status',payload, useBearerTokenHeaders())
            .then(response => {
                resolve(response.data)
            }).catch(err => resolve({status: false, message: err}));
    })
}
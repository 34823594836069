import {driverEnrolmentService} from "../../apis/driver.enrolment.service";

const state = {
    prospectiveDrivers: [],
    filtered: [],
    selectedProspectiveDriver: {},
    filterApplied: false
}
const getters = {
    'GET_PROSPECTIVE_DRIVERS' (state) {
        if(state.filterApplied){
            return state.filtered;
        }
        return state.prospectiveDrivers;
    },
    'GET_SELECTED_PROSPECTIVE_DRIVER' (state) {
        return state.selectedProspectiveDriver;
    },
    'GET_SELECTED_PROSPECTIVE_DRIVER_FILES' (state) {
        return state.selectedProspectiveDriver ? state.selectedProspectiveDriver.files : [];
    },
    totalDriverOnboards(state){
        return state.prospectiveDrivers.length
    },
    totalDriversInStage1(state){
        return state.prospectiveDrivers.filter(el => el.files.length === 0).length
    },
    totalDriversInStage2(state){
        return state.prospectiveDrivers.filter(el => el.files.length > 0).length
    }
}

const mutations = {
    'SET_PROSPECTIVE_DRIVERS' (state, payload = []) {
        state.prospectiveDrivers = payload;
        state.filterApplied = false;
    },
    'SET_SELECTED_PROSPECTIVE_DRIVERS' (state, payload = {}) {
        state.selectedProspectiveDriver = payload;
    },
    'UPDATE_FILES' (state,{ id, newStatus, comment }){
        const index = state.selectedProspectiveDriver.files.findIndex(file => file.id === id)
        state.selectedProspectiveDriver.files[index].status = newStatus;
        state.selectedProspectiveDriver.files[index].comment = comment;
    },
    'REMOVE_FILE' (state, docId) {
        const index = state.selectedProspectiveDriver.files.findIndex(file => file.id === docId)
        state.selectedProspectiveDriver.files.splice(index, 1);
    },
    'ADD_FILE' (state, file) {
        state.selectedProspectiveDriver.files.push(file)
    },
    'FILTER_DRIVERS' (state, status) {
        console.log('status: ' , status);
        console.log('state.prospectiveDrivers: ' , state.prospectiveDrivers);
        if(status === 'service-selected'){
            state.filtered = state.prospectiveDrivers.filter(e => e.prospective_driver.status === 'service-selected')
            state.filterApplied = true;
        }else if(status === 'uploading-files'){
            state.filtered = state.prospectiveDrivers.filter(e => e.prospective_driver.files && e.prospective_driver.files.length > 0)
            state.filterApplied = true;
        } else if(status === "in-progress") {
            state.filtered = state.prospectiveDrivers.filter(e => e.prospective_driver.status === 'in-progress' &&  !e.prospective_driver.files )
            state.filterApplied = true;
        } else{
            // remove filters
            state.filtered = []
            state.filterApplied = false;
        }

        console.log('state.filterApplied ' , state.filtered);
    },
    'REMOVE_FILTER_DRIVERS' (state) {
        state.filtered = []
        state.filterApplied = false;
    }
}

const actions = {

    filterProspectiveDriverList({commit, status}, payload){
            commit('FILTER_DRIVERS', payload);
    },

    removeFilterProspectiveDriverList({commit, status}){
        commit('REMOVE_FILTER_DRIVERS');
    },

    fetchProspectiveDriverList({commit, dispatch}){

        dispatch('showLoader')
        driverEnrolmentService.fetchProspectiveDriverList().then(data => {
            dispatch('hideLoader')
            if(!data.status){
                dispatch('error', {message:data.message})
                return;
            }
            commit('SET_PROSPECTIVE_DRIVERS', data.extra);
        })
    },

    fetchProspectiveDriverDetail({commit, dispatch}, id){
        dispatch('showLoader')
        driverEnrolmentService.fetchProspectiveDriverDetail(id).then(data => {
            dispatch('hideLoader')
            if(!data.status){
                dispatch('error', {message:data.message})
                return;
            }
            commit('SET_SELECTED_PROSPECTIVE_DRIVERS', data.extra);
        })
    },

    generateEnrolmentFileDownloadToken({dispatch}, docId){

        dispatch('showLoader')
        return new Promise((resolve) => {
            driverEnrolmentService.generateEnrolmentFileToken(docId).then(data => {
                dispatch('hideLoader')
                if(!data.status){
                    dispatch('error', {message:data.message})
                    return;
                }

                const token = data.extra;
                resolve(token);

            })
        })
    },

    updateEnrolmentDocument({commit, dispatch}, payload){

        dispatch('showLoader')
        driverEnrolmentService.updateEnrolmentDocumentStatus(payload).then(data => {

            dispatch('hideLoader')
            if(!data.status){
                dispatch('error', {message:data.message})
                return;
            }

            commit('UPDATE_FILES', {
                id: payload.docId,
                newStatus: payload.newStatus,
                comment: payload.comment
            });

            dispatch('success', {message: 'Status changed'});

        })
    },

    removeEnrolmentDocument({commit, dispatch}, docId){

        dispatch('showLoader')
        driverEnrolmentService.removeEnrolmentDocument(docId).then(data => {
            dispatch('hideLoader')

            if(!data.status){
                dispatch('error', {message:data.message, showSwal: true})
                return;
            }

            commit('REMOVE_FILE', docId)
            dispatch('success', {message: 'File removed'});
        })
    },

    addNewDriverEnrolmentDocument({commit, dispatch}, {userId, formData}){
        dispatch('showLoader')
        driverEnrolmentService.addNewDriverEnrolmentFile(userId, formData).then(data => {
            dispatch('hideLoader')

            if(!data.status){
                dispatch('error', {message:data.message, showSwal: true})
                return;
            }

            commit('ADD_FILE', data.extra)
            dispatch('success', {message: 'File added', showSwal: true});
        })
    },

    addOrUpdateEnrolmentDetails({commit, dispatch}, {userId, formData}){
        dispatch('showLoader')
        driverEnrolmentService.addOrUpdateEnrolmentDetails(userId, formData).then(data => {
            dispatch('hideLoader')

            if(!data.status){
                dispatch('error', {message:data.message, showSwal: true})
                return;
            }

            dispatch('success', {message: 'Completed', showSwal: true});
            commit('SET_SELECTED_PROSPECTIVE_DRIVERS', data.extra);
        })
    },

    markAsCompleted({commit, dispatch}, userId){

        dispatch('showLoader')
        driverEnrolmentService.markAsCompleted(userId).then(data => {
            dispatch('hideLoader')

            if(!data.status){
                dispatch('error', {message:data.message, showSwal: true})
                return;
            }

            dispatch('success', {message: 'Completed', showSwal: true});
            commit('SET_SELECTED_PROSPECTIVE_DRIVERS', data.extra);
        })

    }

}


export default {
    state,
    getters,
    mutations,
    actions
}

const state = {
    isLoading: false,
    isSectionLoading: false
}

const getters = {
    'LOADER' (state)  {
        return state.isLoading
    },
    'SECTION_LOADER' (state)  {
        return state.isSectionLoading
    },
}
const mutations = {
    'ENABLE_LOADER' (state) {
        state.isLoading = true;
    },
    'DISABLE_LOADER' (state) {
        state.isLoading = false;
    },
    'ENABLE_SECTION_LOADER' (state) {
        state.isSectionLoading = true;
    },
    'DISABLE_SECTION_LOADER' (state) {
        state.isSectionLoading = false;
    }
}


const actions = {
    showLoader({commit}) {
        commit('ENABLE_LOADER')
    },
    hideLoader({commit}) {
        commit('DISABLE_LOADER')
    },
    showSectionLoader({commit}){
        commit('ENABLE_SECTION_LOADER')
    },
    hideSectionLoader({commit}){
        commit('DISABLE_SECTION_LOADER')
    },
}


export default {
    state,
    getters,
    actions,
    mutations
}
import {driversService} from "../../apis/drivers.service";

const state = {
    drivers: [],
    driverProviders: [],
    selectedDriver: []
}

const getters = {
    'GET_DRIVERS'(state) {
        return state.drivers;
    },
    'GET_SELECTED_DRIVER'(state) {
        return state.selectedDriver;
    },
    'GET_DRIVER_PROVIDERS'(state) {
        return state.driverProviders;
    },
}

const mutations = {
    'SET_DRIVERS'(state, drivers) {
        return state.drivers = drivers;
    },
    'SET_SELECTED_DRIVER'(state, driver) {
        return state.selectedDriver = driver;
    },
    'SET_DRIVER_PROVIDERS'(state, data) {
        return state.driverProviders = data;
    },
}

const actions = {

    getDrivers({commit, dispatch}, {limit = 5, status = '', showLoader = true}) {
        return new Promise((resolve, reject) => {
            if (showLoader) {
                dispatch('showLoader')
            }
            driversService.getDrivers(limit, status).then(data => {
                dispatch('hideLoader')
                if (!data.status) {
                    dispatch('error', {message: data.message})
                    reject()
                    return;
                }
                commit('SET_DRIVERS', data.extra);
                resolve(data);
            })
        })
    },

    getDriver({commit, dispatch}, id) {
        dispatch('showLoader')
        driversService.getDriver(id).then(data => {
            dispatch('hideLoader')
            if (!data.status) {
                dispatch('error', {message: data.message})
                return;
            }
            commit('SET_SELECTED_DRIVER', data.extra);
        })
    },

    searchDriver({commit, dispatch}, value) {
        dispatch('showSectionLoader')
        driversService.searchDrivers(value).then(data => {
            dispatch('hideSectionLoader')
            if (!data.status) {
                return;
            }
            commit('SET_DRIVERS', data.extra);
        })
    },

    clearDrivers({commit}) {
        commit('SET_DRIVERS', []);
    },

    addDriver({commit, dispatch}, payload) {
        return new Promise((resolve, reject) => {
            console.log('state payload: ', payload)
            dispatch('showLoader')
            driversService.addDriver(payload).then(data => {
                dispatch('hideLoader')
                if (!data.status) {
                    dispatch('error', {message: data.message, showSwal: true})
                    reject();
                    return;
                }

                resolve();
                dispatch('success', {message: data.message, showSwal: true})
            })
        })
    },

    updateDriver({commit, dispatch}, {payload, id}) {
        return new Promise((resolve, reject) => {
            console.log('state payload: ', payload)
            dispatch('showLoader')
            driversService.updateDriver(payload, id).then(data => {
                dispatch('hideLoader')
                if (!data.status) {
                    dispatch('error', {message: data.message, showSwal: true})
                    reject();
                    return;
                }

                resolve();
                dispatch('success', {message: data.message, showSwal: true})
            })
        })
    },

    assignProviderExternalIdentifier({commit, dispatch}, payload) {
        return new Promise((resolve, reject) => {
            console.log('state payload: ', payload)
            dispatch('showLoader')
            driversService.assignProviderExternalIdentifier(payload).then(data => {
                dispatch('hideLoader')
                if (!data.status) {
                    dispatch('error', {message: data.message, showSwal: true})
                    reject();
                    return;
                }

                resolve();
                dispatch('success', {message: data.message, showSwal: true})
            })
        })
    },

    fetchProviderExternalIdentifier({commit, dispatch}, id) {

        commit('SET_PROVIDERS', []);
        commit('SET_DRIVER_PROVIDERS', []);

        return new Promise((resolve, reject) => {
            dispatch('showSectionLoader')
            driversService.fetchProviderExternalIdentifier(id).then(data => {
                dispatch('hideSectionLoader')
                if (!data.status) {
                    dispatch('error', {message: data.message, showSwal: true})
                    reject();
                    return;
                }

                resolve();
                commit('SET_PROVIDERS', data.extra['providers']);
                commit('SET_DRIVER_PROVIDERS', data.extra['driver_providers']);
                //dispatch('success', {message: data.message, showSwal: true})
            })
        })
    },


}

export default {
    state,
    getters,
    actions,
    mutations
}